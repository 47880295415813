import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Post = styled.li`
  position: relative;
  border-radius: 0.5rem;
  background: white;
  overflow: hidden;
  box-shadow: 6px 6px 17px 0 rgba(82, 76, 76, 0.06);
  margin: 0 0 1em 0;
  width: 100%;
  transition: 0.2s all;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => {
      if (props.featured) return '0 0 100%'
      if (props.isRelatedPostList) return '0 0 49%'
      else return '0 0 32%'
    }};
  }
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    }
  }
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  margin: 1rem 1rem 0.5rem 1rem;
`

const Date = styled.h3`
  margin: 0 1rem 0.5rem 1rem;
  color: gray;
`

const ReadingTime = styled.h4`
  margin: 0 1rem 1.5rem 1rem;
  color: gray;
`

const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`

const Card = ({
  slug,
  heroImage,
  title,
  publishDate,
  body,
  body: {
    childMarkdownRemark: { timeToRead },
  },
  ...props
}) => {
  const heroImageFluid = heroImage ? heroImage.fluid : null
  const childMarkdownRemarkExcerpt = body
    ? body.childMarkdownRemark.excerpt
    : null

  return (
    <Post featured={props.featured} isRelatedPostList={props.isRelatedPostList}>
      <Link to={`/${slug}/`}>
        {heroImageFluid && (
          <Img fluid={heroImageFluid} backgroundColor={'#eeeeee'} />
        )}
        <Title>{title}</Title>
        <Date>{publishDate}</Date>
        <ReadingTime>{timeToRead} min read</ReadingTime>
        {childMarkdownRemarkExcerpt && (
          <Excerpt
            dangerouslySetInnerHTML={{
              __html: body.childMarkdownRemark.excerpt,
            }}
          />
        )}
      </Link>
    </Post>
  )
}

export default Card
